<template>
    <v-card class="mb-7 w-100 fbcolor">
        <v-card-text class="pa-7">
            <div class="d-flex">
                <div class="stats">
                    <h1 class="white--text font-weight-regular">{{ tagMark }}+</h1>
                    <h6 class="white--text body-1 mt-3">Me Interesa</h6>
                    <v-btn depressed rounded class="mt-4 body-1 text-capitalize">
                        Chequear lista
                    </v-btn>
                </div>
                <div class="stats-icon text-right ml-auto">
                    <v-icon large class="display-5 white--text">mdi-bookmark-check</v-icon>
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
    import dashBoardService from '@/providers/DashboardService';
    
    export default {
        name: "TheFollowerCardA",
        data: () => ({
            tagMark: 0
        }),
        methods: {
            getCountTagMark() {
                dashBoardService.getCountTags('mark').then(fechData => {
                    this.tagMark = fechData;
                });
            }
        },
        mounted() {
            this.tagMark = 0; // 3257;
            this.getCountTagMark();
        }
    };
</script>